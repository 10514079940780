import { mapGetters, mapState } from 'vuex';
import { auth } from '@/db';

export default {
  methods: {
    redirectTo(routeName) {
      this.$router.push({ name: routeName });
    },
    logIn(user) {
      if (user) {
        this.$store.commit('SET_USER', user);
        this.$router.push({ name: 'projects' });
      }
    },
    logOut() {
      const {
        $store,
        $router,
        $route,
        $emit,
      } = this;

      auth.signOut()
        .then(() => {
          $store.dispatch('logOutUser');

          if ($route.name !== 'home') {
            $router.push({ name: 'home' });
          }

          $emit('logged-out');
        })
        .catch(() => {
          // TODO: Show error
        });
    },
    // Verify that the UID matches to that there's no trickery afoot.
    checkUser(toCheck) {
      return this.isLoggedIn
        ? auth.currentUser.uid === toCheck
        : false;
    },
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isLoggedIn']),
    userId() {
      return this.user ? this.user.uid : null;
    },
  },
};
