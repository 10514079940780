<template>
  <b-loading :is-full-page="full" v-model="show" :can-cancel="true"></b-loading>
</template>

<script>
export default {
  props: {
    full: {
      required: false,
      type: Boolean,
      default: false,
    },

    closable: {
      required: false,
      type: Boolean,
      default: false,
    },

    show: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
