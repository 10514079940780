// Conveniently import this file anywhere to use db

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'firebaseui/dist/firebaseui.css';

export const db = firebase
  .initializeApp({
    apiKey: 'AIzaSyBiROa_JCBT7h_LVQJ5KoykAeh_MnAjfzo',
    authDomain: 'standuppie.firebaseapp.com',
    projectId: 'standuppie',
    storageBucket: 'standuppie.appspot.com',
    messagingSenderId: '109244599902',
    appId: '1:109244599902:web:bc231c38d8403046a6eecf',
    measurementId: 'G-1S1EW8JLG6',
  })
  .firestore();

export const auth = firebase.auth();

export const loginProviders = [
  firebase.auth.EmailAuthProvider.PROVIDER_ID,
  firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  firebase.auth.GithubAuthProvider.PROVIDER_ID,
];

export const registerProviders = [
  firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  firebase.auth.GithubAuthProvider.PROVIDER_ID,
];

// Export types that exists in Firestore - Uncomment if you need them in your app
// const { Timestamp, GeoPoint } = firebase.firestore
// export { Timestamp, GeoPoint }
