<template>
  <b-navbar-item tag="div">
    <div class="buttons">
      <router-link :to="{ name: 'register' }" :class="registerClasses" v-if="!isLoggedIn">
        <strong>Sign up</strong>
      </router-link>

      <router-link :to="{ name: 'login' }" :class="loginClasses" v-if="!isLoggedIn">
        Log in
      </router-link>

      <a :class="loginClasses" @click="logOut" v-else>
        Log out
      </a>
    </div>
  </b-navbar-item>
</template>

<script>
import auth from '@/mixins/auth';

export default {
  name: 'NavAuth',
  mixins: [auth],
  props: {
    fullWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
    loginClasses: {
      required: false,
      type: String,
      default: 'button is-light',
    },
    logoutClasses: {
      required: false,
      type: String,
      default: 'button is-light',
    },
    registerClasses: {
      required: false,
      type: String,
      default: 'button is-info',
    },
  },
};
</script>

<style scoped>

</style>
