import { pageTitle } from '@/plugins/htmlHelper';

export default [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { title: 'Standuppie' },

  },
  {
    path: '/features',
    name: 'features',
    component: () => import(/* webpackChunkName: "features" */ '../views/Features.vue'),
    meta: { title: pageTitle('Features') },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
    meta: { title: pageTitle('Login') },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Register.vue'),
    meta: { title: pageTitle('Register') },
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
    meta: { title: pageTitle('Projects') },
  },
  {
    path: '/project/:id',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue'),
  },
];
