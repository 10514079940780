import Vue from 'vue';
import Vuex from 'vuex';
import { firestorePlugin } from 'vuefire';
import VuexPersistence from 'vuex-persist';
import { firestoreAction, vuexfireMutations } from 'vuexfire';

import { db } from '@/db';

Vue.use(Vuex);
Vue.use(firestorePlugin);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const defaultState = ({
  projects: [],
  tasks: [],
  activeDate: null,
  user: null,
  app: {
    showLoadingOverlay: false,
    manualHomeNavigation: false,
  },
});

export default new Vuex.Store({
  state: () => ({
    ...defaultState,
  }),
  mutations: {
    ...vuexfireMutations,

    SET_USER: (state, payload) => {
      state.user = payload;
    },

    SET_APP_SETTING: (state, { item, value }) => {
      state.app[item] = value;
    },

    SET_ACTIVE_DATE: (state, date) => {
      state.activeDate = date;
    },
  },
  actions: {
    bindProjectsRef: firestoreAction(async (context, userId) => {
      await new Promise((resolve) => {
        context.bindFirestoreRef(
          'projects',
          db.collection('projects').where('user_id', '==', userId),
        )
          // Hides a loading overlay after Firestore has resolved.
          .then((result) => {
            context.commit('SET_APP_SETTING', { item: 'showLoadingOverlay', value: false });
            resolve(result);
          });
      });
    }),

    bindTasksRef: firestoreAction(async (context, userId) => {
      await new Promise((resolve) => {
        context.bindFirestoreRef(
          'tasks',
          db.collection('tasks')
            .where('user_id', '==', userId),
        )
          // Hides a loading overlay after Firestore has resolved.
          .then((result) => {
            context.commit('SET_APP_SETTING', { item: 'showLoadingOverlay', value: false });
            resolve(result);
          });
      });
    }),

    addSubTask: firestoreAction((context, { parent, newTask }) => {
      const existingSubTasks = parent.subTasks || [];

      return db.collection('tasks')
        .doc(parent.id)
        .update({
          subTasks: existingSubTasks.concat([newTask]),
        })
        .then((result) => result);
    }),

    logInUser: ({ commit }, user) => commit('SET_USER', user),

    logOutUser: ({ commit }) => commit('SET_USER', defaultState.user),

    setActiveDate: ({ commit }, date) => commit('SET_ACTIVE_DATE', date),

    showLoading: ({ commit }) => commit('SET_APP_SETTING', {
      item: 'showLoadingOverlay',
      value: true,
    }),

    hideLoading: ({ commit }) => commit('SET_APP_SETTING', {
      item: 'showLoadingOverlay',
      value: false,
    }),
  },
  getters: {
    getProject: (state) => (id) => state.projects
      .find((project) => project.uuid === id),

    getTasksForProject: (state) => (projectId, date) => state.tasks
      .filter((task) => task.project_uuid === projectId && task.date === date),

    getActiveDate: (state) => state.activeDate,

    isLoggedIn: (state) => !!state.user,

    isLoading: (state) => state.app.showLoadingOverlay,

    isManualHomeNav: (state) => state.app.manualHomeNavigation,
  },
  plugins: [vuexLocal.plugin],
});
