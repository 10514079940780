import Vue from 'vue';
import Buefy from 'buefy';
import Vuelidate from 'vuelidate';
import VuePageTransition from 'vue-page-transition';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/scss/app.scss';

Vue.use(Buefy);
Vue.use(Vuelidate);
Vue.use(VuePageTransition);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
