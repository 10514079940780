<template>
  <div>
    <loading :full="true" :show="isLoading" />
    <Nav/>

    <vue-page-transition name="fade-in-left">
      <router-view></router-view>
    </vue-page-transition>
  </div>
</template>

<script>
import Nav from '@/components/global/Nav';
import Loading from '@/components/global/Loading';
import { mapGetters } from 'vuex';
import auth from '@/mixins/auth';

export default {
  name: 'App',
  mixins: [auth],
  components: {
    Nav,
    Loading,
  },

  beforeMount() {
    this.$store.dispatch('showLoading');
    this.$store.dispatch('bindProjectsRef', this.userId);
    this.$store.dispatch('bindTasksRef', this.userId);
  },
  computed: {
    ...mapGetters(['isLoading', 'isLoggedIn']),
  },
};
</script>
