<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item @click="manualNav">
        <b-image :src="`${publicPath}img/logo-without-text-80.png`"></b-image>
      </b-navbar-item>
    </template>
    <template #start>
      <div
        class="my-2"
        v-for="(link,index) in links"
        v-bind:key="index"
      >
        <b-navbar-item
          v-if="!link.auth"
          tag="router-link" :to="{ name: link.routeName }"
        >
          {{ link.value }}
        </b-navbar-item>

        <b-navbar-item
          v-else-if="link.auth && isLoggedIn"
          tag="router-link" :to="{ name: link.routeName }"
        >
          {{ link.value }}
        </b-navbar-item>
      </div>

    </template>

    <template #end>
      <nav-auth/>
    </template>
  </b-navbar>
</template>

<script>
import auth from '@/mixins/auth';
import NavAuth from './NavAuth';

export default {
  name: 'Nav',
  mixins: [auth],
  components: {
    NavAuth,
  },
  data() {
    return {
      links: [
        {
          value: 'Projects',
          routeName: 'projects',
          auth: true,
        },
        {
          value: 'Features',
          routeName: 'features',
        },
      ],
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    manualNav() {
      if (this.$route.name !== 'home') {
        this.$store.commit('SET_APP_SETTING', {
          item: 'manualHomeNavigation',
          value: true,
        });

        this.$router.push({ name: 'home' });
      }
    },
  },
};
</script>

<style scoped>

</style>
